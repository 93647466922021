import { IMyPartner } from '@/types/interfaces'
import { defineStore } from 'pinia'

interface IPartnersStore {
  partners: IMyPartner[] | null
}

export const usePartnersStore = defineStore('usePartnersStore', {
  state: (): IPartnersStore => ({
    partners: null,
  }),
  actions: {
    setPartners(data: IMyPartner[] | null) {
      this.partners = data
    },
  },
})
