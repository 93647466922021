import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import egalWidgets from '@egalteam/widget-library'
import Toast, { PluginOptions } from 'vue-toastification'
import Maska from 'maska'

import '@egalteam/widget-library/dist/style.css'
import '@/assets/style/global.scss'
import '@/assets/style/excel-styles.scss'
import 'vue-toastification/dist/index.css'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { IReCaptchaOptions } from 'vue-recaptcha-v3/dist/IReCaptchaOptions'

// @ts-ignore
const options: PluginOptions = {
  timeout: 10000,
  // @ts-ignore
  position: 'top-right',
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  icon: false,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
}

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const noCaptchaSiteKey = process.env.VUE_APP_NOCAPTCHA_SITEKEY || '6LdWzoMjAAAAAHPIDgD_uIFuHQX3pQmZeyrp_RC0'

createApp(App)
  .use(router)
  .use(Maska)
  .use(Toast, options)
  .use(pinia) // @ts-ignore
  .use(egalWidgets)
  .directive('click-outside', {
    mounted(el: any, binding: any, vnode: any) {
      el.clickOutsideEvent = function (event: any) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el)
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  })
    .use(VueReCaptcha, { siteKey: noCaptchaSiteKey } as IReCaptchaOptions)
  .mount('#app')
