import { Roles } from '@/types/enums'
import { Dictionary } from '@/types/types'

export const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const phoneMask = '+7 ### ###-##-##'

export const backValidationDictionary = {
  checkPair: {
    error:
      'Validation failed! Pair of checking account and bic must be unique Pair of bic and checking_account must be unique',
    message: 'Пользователь с таким расчетным счетом и БИК уже существует',
  },
}

// export const cartDateValidation = {
//   checkPair: {
//     error:
//       'Validation failed! The delivery date must be a date after order date.',
//     message: 'Дата доставки не может ',
//   },
// }

/**
 * Переключение иконки и видимости пароля
 * @param eventTagName
 * @param obj
 */
export const togglePasswordEyeIcon = (
  eventTagName: string,
  obj: { type: string; iconRight: string },
) => {
  const iconTags = ['svg', 'use']
  if (iconTags.includes(eventTagName)) {
    obj.type = obj.type === 'password' ? 'text' : 'password'
    obj.iconRight = obj.iconRight === 'eye' ? 'eye-slash' : 'eye'
  }
}

export const rolesDictionary: Dictionary = {
  [Roles.Customer]: 'Покупатель',
  [Roles.Farmer]: 'Поставщик',
  [Roles.Admin]: 'Администратор',
}

export const RUSTORE_LINK = 'https://www.rustore.ru/catalog/app/com.mobile'
