import { IUser } from '@/types/interfaces'
import { defineStore } from 'pinia'

interface IUserAuthStore {
  isAuth: boolean
  user: IUser | null
}

export const useUserAuthStore = defineStore('userAuthStore', {
  state: (): IUserAuthStore => ({
    isAuth: false,
    user: null,
  }),
  actions: {
    setUser(data: IUser | null) {
      this.user = data
    },
    setIsAuth(value: boolean) {
      this.isAuth = value
    },
  },
})
