import { Document } from '@/types/types'
import { DocumentAPI } from '@/api/DocumentAPI'
import {
  IOrder,
  IOrderPosition,
  IParameter,
  IPriceOption,
} from '@/types/interfaces'
import { Roles, TagType } from '@/types/enums'
import { ProfileDocumentAPI } from '@/api/ProfileDocumentAPI'
import { OrderDocumentAPI } from '@/api/OrderDocumentAPI'
import { useCookies } from 'vue3-cookies'
import { PartnerDocumentAPI } from '@/api/PartnerDocumentAPI'
import { h } from 'vue'
import { InfoCircleFilled } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'
import { NotificationArgsProps } from 'ant-design-vue/es/notification'
import { CompanyDocumentAPI } from '@/api/CompanyDocumentAPI'

export const dayEnding = (day: number) => {
  if (day > 9 && day < 21) {
    return 'дней'
  } else if (day === 0) {
    return 'дней'
  } else {
    if (day % 10 === 1) {
      return 'день'
    } else if (day % 10 > 1 && day % 10 < 5) {
      return 'дня'
    } else {
      return 'дней'
    }
  }
}

const { cookies } = useCookies()

export const roleMap: Record<string, Roles> = {
  farmer: Roles.Farmer,
  customer: Roles.Customer,
  admin: Roles.Admin,
}

export const systemDocsName = {
  BOL: 'Товарная накладная',
  CBOL: 'Товарно-транспортная накладная',
  PI: 'Счет на оплату',
  invoice: 'Счёт-фактура',
}

export const onlyNumbersPhone = (phone = ''): string => {
  return phone
      .replace(/[а-яА-Яa-zA-Z]/g, '') // remove Russian and English letters
      .split(' ')
      .join('')
      .split('-')
      .join('')
      .split('+')
      .join('')
      .split('(')
      .join('')
      .split(')')
      .join('')
}

// форматирует строку по передаваемому паттерну
export const formatWithPattern = (
  str: string,
  pattern = '+# ### ###-##-##',
) => {
  let i = 0
  return pattern.replace(/#/g, (_) => str[i++])
}

// Обрезает текст если он превышает max символов
export const trimText = (str: string, max = 27): string => {
  if (str.length > max) {
    return str.slice(0, max) + '...'
  } else return str
}

export const getToday = () => {
  const today = new Date()
  const splitToday = today.toLocaleDateString().split('.')
  return `${splitToday[2]}-${splitToday[1]}-${splitToday[0]}`
}

// Определение цены с НДС
export const priceWithVat = (price: number, vat_percent: number) => {
  return (Number(price) + Number(price) * (Number(vat_percent) / 100)).toFixed(
    2,
  )
}
// Форматировать в дд.мм.гг формат
export const expiresData = (date: string) => {
  let newDate = date.slice(0, 10)
  newDate = newDate.split('-').join('.')
  return newDate
}
// Вывести сколько осталось дней, относительно текущего дня
export const expiresDays = (date: string) => {
  const newDate = new Date(date)
  const todayTime = new Date()
  const diffTime = Math.abs(Number(newDate) - Number(todayTime))
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

/**
 * Отметить 1 чекбокс из компонента CheckboxColumn
 * @param idx
 * @param arr
 */
export const checkItem = (idx: number, arr: any) => {
  arr[idx].value = !arr[idx].value
}

/**
 * Отметить все чекбоксы из компонента CheckboxColumn
 * @param arr
 */
export const checkAll = (arr: any) => {
  arr.map((i: { value: boolean }) => (i.value = true))
}
export const uncheckAll = (arr: any) => {
  arr.map((i: { value: boolean }) => (i.value = false))
}

/**
 * Добавляет числу (или строке с числом) пробел после каждых 3ех цифр (приводит к виду 1 000 000)
 * @param num - число или строка с числом (напрмиер, 1000000)
 */
export const splitNumberByThreeDigits = (num: number | string): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const splitNumberByThreeDigitsNumber = (
  num: number | string,
  toFixed: number,
): string => {
  return Number(num)
    .toFixed(toFixed)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

/**
 * Определение окончания слова
 */
export const determineWordEnd = (
  wordToDeclension: string,
  itemsAmount: number,
  declensionCallback: any,
): string => {
  const word = wordToDeclension + declensionCallback(itemsAmount)
  return `${itemsAmount} ${word}`
}

/**
 * Получает значение name по ключу value из enumа
 * @param enumObj
 * @param data
 */
export const getValueFromEnum = (enumObj: any, data: any) => {
  const result = enumObj.find((i: any) => i.value === data)
  if (result) return result?.name
}

/**
 * Получает значение value по ключу name из enumа
 * @param enumObj
 * @param data
 */
export const getEnumFromValue = (enumObj: any, data: any) => {
  const result = enumObj.find((i: any) => i.name === data)
  return result.value
}
/**
 * Проходит по массиву и ищет его значение из строки, затем делает первую букву заглавной
 * @param array
 * @param string
 * @param array
 */
export const getNameFromArray: (string: string, array: string[]) => string = (
  string: string,
  array: string[],
) => {
  const name = array.find((category) => string.match(new RegExp(category, 'i')))

  return name ? name.charAt(0).toUpperCase() + name.slice(1) : ''
}

/**
 * Сортировка продуктов по существующим в массиве категориям
 * Возвращает массив с объектами вида { category: 'Овощи', items: [] }
 * @param arr - неотсортированный массив
 */
export const sortProductsByType = (arr: any) => {
  const categories = [] as string[]

  const res = [] as any
  arr.forEach((item: any) => {
    if (item.is_deleted) return
    if (!categories.includes(item.secondCategory)) {
      categories.push(item.secondCategory)
      res.push({
        category: item.secondCategory,
        items: [item],
      })
    } else {
      const x = res.find((r: any) => r.category === item.secondCategory)
      x.items.push(item)
    }
  })

  res.sort((a: any, b: any) =>
    a.category?.toLowerCase() < b.category?.toLowerCase() ? -1 : 1,
  )
  return res
}

/**
 * Определение склонения для слова штука
 * @param number
 */
export const declensionThing = (number: number) => {
  const endings = ['а', 'и', '']
  return endings[number === 1 ? 0 : number >= 2 && number <= 4 ? 1 : 2]
}
/**
 * Определение склонения для слова штука
 * @param number
 */
export const declensionLitres = (number: number) => {
  const endings = ['', 'а', 'ов']
  return endings[number === 1 ? 0 : number >= 2 && number <= 4 ? 1 : 2]
}
/**
 * Определение склонения для слова день
 * @param number
 */
export const declensionDays = (number: number | string) => {
  const newNumber = Math.abs(+number) % 100 // Оставляем только две последние цифры числа
  const remainder = newNumber % 10 // Определяем последнюю цифру

  const form1 = 'день'
  const form2 = 'дня'
  const form3 = 'дней'

  if (number >= 11 && number <= 19) {
    return form3
  } else if (remainder === 1) {
    return form1
  } else if (remainder >= 2 && remainder <= 4) {
    return form2
  } else {
    return form3
  }
}
/**
 * Определение склонения для слова ставка
 * @param number
 */
export const declensionBets = (number: number | string) => {
  const num = typeof number === 'string' ? parseInt(number, 10) : number

  // Проверка на невалидные значения
  if (isNaN(num)) {
    return ''
  }

  const lastDigit = num % 10
  const lastTwoDigits = num % 100

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return 'ставок'
  } else if (lastDigit === 1) {
    return 'ставка'
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return 'ставки'
  } else {
    return 'ставок'
  }
}
/**
 * Определение склонения для слова продукт
 * @param number
 */
export const declensionProducts = (number: number | string) => {
  const newNumber = Math.abs(+number) % 100 // Оставляем только две последние цифры числа
  const remainder = newNumber % 10 // Определяем последнюю цифру

  const form1 = 'продукт'
  const form2 = 'продукта'
  const form3 = 'продуктов'

  if (number >= 11 && number <= 19) {
    return form3
  } else if (remainder === 1) {
    return form1
  } else if (remainder >= 2 && remainder <= 4) {
    return form2
  } else {
    return form3
  }
}
export const dateToLocale = (date: string) => {
  return new Date(date).toLocaleDateString()
}

export const getOrderName = (orders: any[], deliveryType: string) => {
  const sorted = orders.sort((a, b) => {
    if (
      a.products[0].farmer?.can_deliver === b.products[0].farmer?.can_deliver
    ) {
      return 0
    }
    if (a.products[0].farmer?.can_deliver) {
      return -1
    } else {
      return 1
    }
  })

  const unqIds: any = []
  if (deliveryType === 'Самовывоз') {
    for (let i = 0; i < orders.length; i++) {
      if (!unqIds.includes(sorted[i].data.farmer_id)) {
        unqIds.push(sorted[i].data.farmer_id)
      }
    }
    if (unqIds.length > 1) {
      return 'комплексный заказ'
    }
  } else {
    for (let i = 0; i < orders.length; i++) {
      if (
        !unqIds.includes(sorted[i].data.farmer_id) &&
        sorted[i].products[0].farmer?.can_deliver
      ) {
        unqIds.push(sorted[i].data.farmer_id)
      }
    }
    if (unqIds.length > 1) {
      return 'комплексный заказ'
    }
  }

  return orders.length > 1 && !unqIds.length
    ? 'комплексный заказ'
    : `${sorted[0].products[0].farmer.legal_form} ${sorted[0].products[0].farmer.name}`
}
export const getOrderNameByOrder = (orders: any[]) => {
  let sorted
  if (orders.length > 1) {
    sorted = orders.sort((a, b) => {
      if (a.farmer?.can_deliver === b.farmer?.can_deliver) {
        return 0
      }
      if (a.farmer?.can_deliver) {
        return -1
      } else {
        return 1
      }
    })
  } else {
    sorted = orders
  }

  const unqIds: any = []
  for (let i = 0; i < orders.length; i++) {
    if (!unqIds.includes(sorted[i].farmer_id)) {
      unqIds.push(sorted[i].farmer_id)
    }
  }
  if (unqIds?.length > 1) {
    return 'комплексный заказ'
  }
  return orders?.length > 1 && !unqIds?.length
    ? 'комплексный заказ'
    : `${sorted[0]?.farmer?.legal_form} ${sorted[0]?.farmer.name}`
}

export const getDeliveryType = (delivery_method: string) => {
  return delivery_method === 'Доставка фермером'
    ? 'Доставка поставщиком'
    : delivery_method + ' клиентом'
}

export const getCustomerOrderName = (
  legalForm: string,
  name: string,
  orders: any[],
) => {
  if (orders.length > 1) {
    return 'комплексный заказ'
  }
  return `${legalForm} ${name}`
}

export const getPackagingNumber = (obj: any): number => {
  let num = 0
  if (obj.farmer_product.product.fullName.includes('10 литров')) num = 10
  if (obj.farmer_product.product.fullName.includes('5 литров')) num = 5
  if (obj.farmer_product.product.fullName.includes('1.5 литра')) num = 1.5
  if (obj.farmer_product.product.fullName.includes('1 литр')) num = 1

  return num
}

export const findDifference = (obj1: any, obj2: any) => {
  const difference = Object.keys(obj1).filter((k) => obj1[k] !== obj2[k])

  const diffObj = {}
  if (difference.length > 0) {
    difference.forEach((key) => {
      diffObj[key] = obj2[key]
    })
    return diffObj
  } else return null
}
// The ORDER of the properties IS IMPORTANT
export const findDifferenceWithValues = (obj1: any, obj2: any) => {
  return Object.values(obj1).filter(
    (first) =>
      !Object.values(obj2).some(
        (second) => JSON.stringify(first) === JSON.stringify(second),
      ),
  )
}

export const getEnding = (total: number) => {
  const end = total % 10 > 1 ? Math.round(total % 10) : total
  if ([21, 31, 41, 51, 61, 71, 81, 91].includes(total)) {
    return 'ие'
  }
  if (end === 1 && total !== 11) {
    return 'ие'
  }
  if (
    [2, 3, 4].includes(end) &&
    ![11, 12, 13, 14, 15, 16, 17, 18].includes(total)
  ) {
    return 'ия'
  }
  return 'ий'
}
// получаем минимальное значение при точках
// export const updaterAmountValue = (
//   ev: string,
//   amountValue: number,
//   minValue: number,
// ): number => {
//   if ((ev.match(/\./g) || []).length > 1) {
//     const secondDotIndex = ev.indexOf('.', ev.indexOf('.') + 1)
//     ev = ev.substring(0, secondDotIndex)
//   }
//
//   if (ev.startsWith('.') || /\.\d*\./.test(String(amountValue))) {
//     return minValue
//   } else {
//     return Number(
//       ev.replace(/[^.\d]/g, '').replace(/^(?!^\.)(\d*\.?)|(\d*)\.?/g, '$1$2'),
//     )
//   }
// }

export const updaterAmountValue = (ev: string): number => {
  const dotCount = (ev.match(/\./g) || []).length
  if (dotCount > 1) {
    const secondDotIndex = ev.indexOf('.', ev.indexOf('.') + 1)
    ev = ev.substring(0, secondDotIndex)
  }

  if (ev.startsWith('.') || /\.\d*\./.test(ev)) {
    return 0
  } else {
    const cleanedValue = ev
      .replace(/[^.\d]/g, '')
      .replace(/^(?!^\.)(\d*\.?)|(\d*)\.?/g, '$1$2')
    const parsedValue = Number(cleanedValue) || 0
    return dotCount > 1
      ? parseFloat(parsedValue.toFixed(dotCount - 1))
      : parsedValue
  }
}

export const getCategoriesPath = (category: string) => {
  switch (category) {
    case 'Говядина':
      return 'beef'
    case 'Картофель':
      return 'potato'
    case 'Лук репчатый':
      return 'bulb_onions'
    case 'Морковь':
      return 'carrot'
    case 'Мраморная говядина':
      return 'marble_beef'
    case 'Молоко':
      return 'milk'
    case 'Свинина':
      return 'pork'
    default:
      return 'beef'
      break
  }
}
export const capitalizeFirstLetter = (str: string) => {
  return str?.replace(/^./, str[0]?.toUpperCase())
}

export const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return ''
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/)
  if (match) {
    return `+${match[1]} ${match[2]} ${match[3]}-${match[4]}-${match[5]}`
  }
}

/**
 * Определение если параметер null или пустая строка вернуть тире
 * @param param
 */
export const isNull = (param: string | null) => {
  if (!param) return '-'
  return param
}

/**
 * Отображение имени файла без даты
 */
export const getDocumentName = (docName: string): string => {
  if (!docName) return ''
  const fileName = docName.split('-')[0]
  const fileType = docName.split('.')[docName.split('.').length - 1]
  return fileName + '.' + fileType
}

/**
 * Длина файла из тела base64 троки в МБ
 */
export const getDocumentSizeBase = (base64String: string): string => {
  if (!base64String) return '2'
  const endPart = base64String.slice(base64String.length - 2)
  const minusNumber = endPart === '==' ? 2 : 1
  return ((base64String.length * 0.75 - minusNumber) / 1000000)
    .toFixed(1)
    .replace('.', ',')
}

/**
 * Функция для получения тела файла по клику на кнпоку скачивания файла
 * И вызов скачивания
 * @param id
 * @param fileState
 * @param ref
 */
export const downloadOnClick = async (
  id: number,
  fileState: Document,
  ref: HTMLAnchorElement | undefined,
) => {
  if (!fileState?.file_body) {
    await DocumentAPI.getDocument(id).then((res) => {
      fileState.file_body = res?.file_body
      fileState.file_name = res?.file_name
      if (ref) {
        ref.download = res?.file_name
        ref.href = res?.file_body
        ref.click()
      }
    })
  } else {
    if (ref) {
      ref.download = fileState?.file_name
      ref.href = fileState?.file_body
      ref.click()
    }
  }
}

export const downloadCompanyOnClick = async (
  id: number,
  fileState: Document,
  ref: HTMLAnchorElement,
) => {
  if (!fileState?.file_body) {
    await DocumentAPI.getCompanyDocument(id).then((res) => {
      fileState.file_body = res?.file_body
      fileState.file_name = res?.file_name
      ref.download = res?.file_name
      ref.href = res?.file_body
      ref.click()
    })
  } else {
    ref.download = fileState?.file_name
    ref.href = fileState?.file_body
    ref.click()
  }
}

/**
 * Функция для получения тела файла по клику на кнпоку скачивания файла
 * И вызов скачивания
 * @param id
 * @param fileState
 * @param ref
 */
export const downloadPartnerDocOnClick = async (
  id: number,
  fileState: Document,
  ref: HTMLAnchorElement | undefined,
) => {
  if (!fileState?.file_body) {
    await PartnerDocumentAPI.getItem(id).then((res) => {
      fileState.file_body = res?.file_body
      fileState.file_name = res?.file_name
      if (ref) {
        ref.download = res?.file_name
        ref.href = res?.file_body
        ref.click()
      }
    })
  } else {
    if (ref) {
      ref.download = fileState?.file_name
      ref.href = fileState?.file_body
      ref.click()
    }
  }
}
export const priceStatusTag = {
  waiting: {
    color: 'blue',
    text: 'Открыт',
  },
  canceled: {
    color: 'default',
    text: 'Закрыт',
  },
  closed: {
    color: 'default',
    text: 'Закрыт',
  },
}

export const priceRecipientStatusTag = {
  waiting: {
    color: 'blue',
    text: 'Ожидание ответа',
  },
  received: {
    color: 'blue',
    text: 'Ожидание ответа',
  },
  refused: {
    color: 'default',
    text: 'Вы отказались',
  },
  answered: {
    color: 'green',
    text: 'Вы ответили',
  },
  canceled: {
    color: 'default',
    text: 'Отменён',
  },
  closed: {
    color: 'default',
    text: 'Закрыт',
  },
  expired: {
    color: 'default',
    text: 'Закрыт',
  },
}

export const profileDownloadOnClick = async (
  id: number,
  fileState: Document,
  ref: HTMLAnchorElement,
) => {
  if (!fileState.file_body) {
    await ProfileDocumentAPI.getItem(id).then((res) => {
      fileState.file_body = res.file_body
      fileState.file_name = res.file_name
      ref.download = res.file_name
      ref.href = res.file_body
      ref.click()
    })
  } else {
    ref.download = fileState.file_name
    ref.href = fileState.file_body
    ref.click()
  }
}

export const orderDownloadOnClick = async (
  id: number,
  fileState: Document,
  ref: HTMLAnchorElement | undefined,
) => {
  if (!fileState.file_body) {
    await OrderDocumentAPI.getItem(id).then((res) => {
      fileState.file_body = res.file_body
      fileState.file_name = res.file_name
      if (ref) {
        ref.download = res.file_name
        ref.href = res.file_body
        ref.click()
      }
    })
  } else {
    if (ref) {
      ref.download = fileState.file_name
      ref.href = fileState.file_body
      ref.click()
    }
  }
}

export const companyDownloadOnClick = async (
  id: number,
  fileState: Document,
  ref: HTMLAnchorElement | undefined,
) => {
  if (!fileState.file_body) {
    await CompanyDocumentAPI.getItem(id).then((res) => {
      fileState.file_body = res.file_body
      fileState.file_name = res.file_name
      if (ref) {
        ref.download = res.file_name
        ref.href = res.file_body
        ref.click()
      }
    })
  } else {
    if (ref) {
      ref.download = fileState.file_name
      ref.href = fileState.file_body
      ref.click()
    }
  }
}
/**
 Функция для  возвращения к месту последнего скролла
 */
export const returnToScroll = () => {
  const scrollY = document.body.style.top
  document.body.style.position = ''
  window.scrollTo(0, Number(scrollY.match(/\d+/gm)![0]))
  document.body.style.top = `0`
}
/**
 * Функция для запоминания скролла
 */
export const rememberScroll = () => {
  document.body.style.top = `-${window.scrollY}px`
  document.body.style.position = 'fixed'
  document.body.style.width = `100%`
}
/**
 * Функция замены точки на запятую
 */
export const replaceDotsWithCommas = (input: string): string => {
  const regex = /\./g
  const replacement = ','
  return input.replace(regex, replacement)
}

/**
 * Функция для сортировки массива с каталогом по алфавиту, кроме фасовка и жирность
 */
export const sortByAlphabet = (
  options: { name: string }[],
  optionTitle = '',
) => {
  if (
    optionTitle.toLowerCase() === 'фасовка' ||
    optionTitle.toLowerCase() === 'жирность'
  )
    return options
  return options.sort((a, b) => a.name.localeCompare(b.name))
}

/**
 * Пребразование ISO даты в формат ДД.ММ.ГГ, деньНедели
 */
export const ISODateTransformer = (ISODate: string) => {
  const dayOfWeek = [
    'воскресенье',
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
  ]
  const date = new Date(ISODate)
  return `${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}.${
    date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
  }.${String(date.getFullYear()).slice(2)}, ${dayOfWeek[date.getDay()]}`
}

/**
 * Определение склонения для слова товар
 * @param number
 */
export const declensionProduct = (number: number) => {
  const endings = ['', 'а', 'ов']
  if (number % 100 > 10 && number % 100 < 15) return endings[2]
  return endings[
    number % 10 === 1 ? 0 : number % 10 >= 2 && number <= 4 ? 1 : 2
  ]
}
/**
 * Форматирование ISO даты в строку ДД.ММ.ГГ
 * @param number
 */
export const ISODateTransformerShort = (date: string) => {
  const [day, month, year] = date.split('-').reverse()
  return [day, month, year.slice(2)].join('.')
}

export const formatPrice = (price: number | string) => {
  if (Number.isInteger(Number(price))) {
    return splitNumberByThreeDigits(Number(price).toFixed(0)) + ' ₽'
  } else {
    return (
      splitNumberByThreeDigits(Number(price).toFixed(2)).replace('.', ',') +
      ' ₽'
    )
  }
}

/**
 * Определение склонения для слова товар/поставщик
 * @param number
 */
export const declensionProvider = (number: number) => {
  const endings = ['', 'а', 'ов']
  if (number % 100 > 10 && number % 100 < 15) return endings[2]
  return endings[
    number % 10 === 1 ? 0 : number % 10 >= 2 && number <= 4 ? 1 : 2
  ]
}

/**
 * Функция для определения типа тэга со статусом заказа
 * @param status строка статуса заказа
 */
export const getStatusType = (status: string): TagType => {
  switch (status) {
    case 'Новый заказ':
      return TagType.GREEN
    case 'Поставщик подписал договор':
    case 'Заказ на доставке':
    case 'Сбор заказа':
    case 'Договор на подписании поставщиком':
    case 'Заказ изменен поставщиком':
      return TagType.BLUE
    case 'Заказ выполнен':
      return TagType.LIGHTGREEN
    case 'Заказ выполнен частично':
      return TagType.ORANGE
    default:
      return TagType.RED
  }
}

/**
 * Функция для форматирования имени файла, отброса даты в конце имени
 * @param name имя файла
 */
export const formatDocumentName = (name: string) => {
  const docRegexp = /(jpg|png|jpeg|pdf|gif|doc|jpe)-\d+-\d+-\d+-\d+/gm
  return name.replace(docRegexp, '')
}

export const getCurrentRole = () => {
  return cookies.get('role')
}

/**
 * Округляет число до 2 десятичных знаков при необходимости.
 *
 * @param {string | number} value - Значение, которое нужно округлить.
 * @return {number} Округленное значение.
 */
export const roundNumber = (value: string | number) => {
  value = Number(value)
  const roundedValue = +(Math.trunc(value * 1000) / 1000).toFixed(2)
  if (roundedValue % 1 !== 0) {
    return roundedValue
  } else {
    return Math.trunc(roundedValue)
  }
}

export const calculatePriceWithVat = (price: number, vat: number) => {
  return (price + (price * vat) / 100).toFixed(2)
}

/**
 + * Разбирает входную строку на число с не более чем 2 десятичными знаками или пустую строку.
 + *
 + * @param {string} value - входная строка для разбора
 + * @return {number|string} разобранное число с не более чем 2 десятичными знаками или пустая строка
 + */
export const inputParser = (value: string) => {
  const intPart = value.split('.')[0]
  let decimalPart = value.split('.')[1]
  if (decimalPart && decimalPart?.length > 2) {
    decimalPart = decimalPart.slice(0, 2)
  }
  if (!!intPart && !decimalPart) {
    return Number(intPart)
  } else if (!!intPart && !!decimalPart) {
    return Number(`${intPart}.${decimalPart}`)
  } else {
    return ''
  }
}

export const getNextValueInPriceOptions = (
  priceOptions: IPriceOption[],
  measurementUnitId: string,
) => {
  const measurementValue = {
    шт: 1,
    кг: 0.01,
  }
  const nextPriceOptions: any[] = []
  priceOptions.forEach((priceOption: IPriceOption, index) => {
    nextPriceOptions.push({
      ...priceOption,
      next_value: priceOptions[index + 1]
        ? +priceOptions[index + 1].min_value -
          measurementValue[measurementUnitId]
        : 1000000,
    })
  })
  return nextPriceOptions
}

/**
 * Форматирует число, добавляет пробелы между тысячными и заменяет запятую
 * @param number
 */
export const formatNumber = (number: number) => {
  // Преобразуем число в строку и разделяем на целую и дробную части (если есть)
  const parts = number.toString().split('.')
  let integerPart = parts[0]
  const decimalPart = parts.length > 1 ? ',' + parts[1] : ''

  // Разделяем целую часть на тройки справа налево
  let formattedInteger = ''
  while (integerPart.length > 3) {
    formattedInteger = ' ' + integerPart.slice(-3) + formattedInteger
    integerPart = integerPart.slice(0, -3)
  }
  formattedInteger = integerPart + formattedInteger

  // Собираем отформатированное число
  const formattedNumber = formattedInteger + decimalPart

  return formattedNumber
}

export const clearPriceWithVat = (price: number | string, vat: number) => {
  return (Number(price) * (100 + Number(vat))) / 100
}

export const splitProductParameters = (parameter: IParameter) => {
  return parameter.rule.replace(/^\/\^|\$\/[gm]/g, '').split('|')
}

export const capitilizeFirstLetter = (str: string | null | undefined) => {
  if (typeof str !== 'string') {
    return str // or return an empty string, or throw an error, depending on your requirements
  }
  return str.replace(/^./, (match) => match.toUpperCase())
}

export const getUserIdTemporary = () => {
  const userDataStr = localStorage.getItem('userData')
  if (userDataStr) {
    return JSON.parse(userDataStr).id as number
  } else return ''
}

export const getUserId = () => {
  const userStr = localStorage.getItem('user')
  if (userStr) {
    return JSON.parse(userStr).id as string
  } else return ''
}

export const getUserCompanyId = () =>
  JSON.parse(localStorage.getItem('user') ?? '').company_id as number

export const clearCookies = async () => {
  cookies.remove('auth')
  cookies.remove('core')
  cookies.remove('umt')
  cookies.remove('ust')
  cookies.remove('onec')
  cookies.remove('_GRECAPTCHA')
  cookies.remove('NID')

  cookies.remove('preRegisterData')
  cookies.remove('registerToken')
  cookies.remove('registerForm')
  cookies.remove('loginForm')

  cookies.remove('user')
  cookies.remove('name')
  cookies.remove('role')
  cookies.remove('emailConfirmed')

  cookies.remove('activeOrganisation')
  cookies.remove('activeBank')

  cookies.remove('chat')
}

export const getSortedArrayByCreatedAt = (array: any[] | undefined) => {
  if (!array) return []
  return [...array].sort(
    (a: any, b: any) =>
      new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime(),
  )
}

export const is_orders_has_difference = (
  order1: IOrder | null,
  order2: IOrder | null,
) => {
  return (
    order1?.status !== order2?.status ||
    order1?.delivery_method !== order2?.delivery_method ||
    order1?.delivery_payment !== order2?.delivery_payment ||
    order1?.payment_type !== order2?.payment_type ||
    order1?.payment_delay_days !== order2?.payment_delay_days ||
    order1?.is_payment_delayed !== order2?.is_payment_delayed ||
    order1?.need_unload !== order2?.need_unload ||
    order1?.palletizing_type !== order2?.palletizing_type ||
    order1?.comment !== order2?.comment
  )
}

export const show_notification = (
  type: 'success' | 'error' | 'info',
  message?: string,
  description?: string,
) => {
  const body: NotificationArgsProps = {
    message: '',
  }
  message && (body['message'] = message)
  description && (body['description'] = description)
  type === 'info' &&
    (body.icon = h(InfoCircleFilled, {
      style: { color: 'rgba(22, 119, 255, 1)' },
    }))
  notification[type](body)
}

export const compare_price_if_equal = (
  goal_number: string | number | null | undefined,
  comparing_number: string | number | undefined,
) => {
  if (!goal_number || !comparing_number) return null

  if (Number(goal_number) === Number(comparing_number)) {
    return null
  }
  return goal_number
}

export const get_sorted_by_is_canceled = (
  array: IOrderPosition[] | undefined,
) => {
  if (!array) return []
  return [...array].sort(
    (a: IOrderPosition, b: IOrderPosition) =>
      Number(a.is_canceled) - Number(b.is_canceled),
  )
}

export function getDifferenceObject<T extends object>(
  obj1: T,
  obj2: T,
): Partial<T> {
  const differences: Partial<T> = {}

  for (const key in obj1) {
    if (
      Object.prototype.hasOwnProperty.call(obj1, key) &&
      Object.prototype.hasOwnProperty.call(obj2, key)
    ) {
      if (obj1[key] !== obj2[key]) {
        differences[key] = obj1[key]
      }
    }
  }

  return differences
}

export const transformPhoneNumber = (phoneNumber: string) => {
  const cleanedPhoneNumber = phoneNumber.replace(/\D+/g, '')
  if (cleanedPhoneNumber.length > 11) {
    return phoneNumber.slice(0, -1)
  }
  if (phoneNumber !== '8') {
    return phoneNumber
  } else {
    return '+7 '
  }
}

export const fillMeasurementUnitIdCommon = (
  fullName: string,
  unitId: string | null,
) => {
  if (unitId === null) {
    const lastTwoChars = fullName?.slice(-2)
    const lastChar = fullName?.slice(-1)
    if (['ШТ', 'Шт', 'Кг', 'КГ', 'шт', 'кг'].includes(lastTwoChars))
      return lastTwoChars
    else if (['Л', 'Г', 'г', 'л'].includes(lastChar)) return lastChar
    else return '-'
  } else return unitId
}
