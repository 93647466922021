export const farmerLinks = [
  {
    to: `/products`,
    label: 'Мои товары',
  },
  {
    to: '/history',
    label: 'История заказов',
  },
  {
    to: '/requests',
    label: 'Запросы цен',
  },
  {
    to: `/partners`,
    label: 'Партнёры',
  },
  {
    to: '/auctions',
    label: 'Торги продажа',
  },
  {
    to: '/reductions',
    label: 'Торги покупка',
  },
]

export const customerLinks = [
  {
    to: '',
    label: 'Каталог',
  },
  {
    to: '/history',
    label: 'История заказов',
  },
  {
    to: '/requests',
    label: 'Запросы цен',
  },
  {
    to: `/partners`,
    label: 'Партнёры',
  },
  {
    to: '/auctions',
    label: 'Торги продажа',
  },
  {
    to: '/reductions',
    label: 'Торги покупка',
  },
]
