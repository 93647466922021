import { IAccountUser } from '@/types/interfaces'

export const DEFAULT_PRODUCT_CARD = {
  product_id: -1,
  price: -1,
  min_value: -1,
  quantity: -1,
  farmer_id: -1,
  measurement_unit_id: '',
  image_name: '',
  image_url: '',
  comment: '',
  prepare_days: -1,
}

export const DEFAULT_USER: IAccountUser = {
  user_id: '',
  contact_number: '',
  checking_account: '',
  description: '',
  user: {
    email: '',
  },
}

export const width100 = { width: '100%' }

export const coordinatesRegex = /^\[-?\d+\.\d+,-?\d+\.\d+\]$/

export const CONTRACT_LINK =
  'https://storage.yandexcloud.net/farm-platform-images/contract.docx'

export const PRIVACY_POLICY_LINK =
  'https://storage.yandexcloud.net/farm-platform-production-images/privacy.pdf'

export const TELEGRAM_LINK = 'https://t.me/easy_retail'

export const OWNER_EMAIL = 'kim@easy-retail.org'
export const OWNER_PHONE = '+7 913 871-12-39'
export const OWNER_NAME = 'Ким Андрей'
export const OWNER_POSITION = 'Генеральный директор'

export const MANAGER_NAME = 'Ремхе Наталия'
export const MANAGER_POSITION = 'Менеджер отдела'
export const MANAGER_EMAIL = 'remkhe@easy-retail.org'
export const MANAGER_PHONE = '+7 953 920-46-97'

export const RUBLE = '₽'
