import EgalActionConstructor from '@/helpers/EgalActionConstructor'
import { APIModels, Microservices } from '@/types/enums'
import { IFilter, ISortPartners } from '@/types/interfaces'
import { SigningAnAgreement } from '@/types/types'
import { useCookies } from 'vue3-cookies'
import axiosInstance from '@/axios'

const { cookies } = useCookies()

const headers = {
  headers: {
    Authorization: cookies.get('core'),
  },
}

class PartnersAPIModel {
  async getItems(sort: any[] = [], filter: any[][] = []): Promise<any> {
    return EgalActionConstructor.getItems(Microservices.Core, APIModels.Partner)
      .setPagination(1, 1)
      .order(sort.length ? sort : [['created_at', 'desc']])
      .filter(filter)
      .call()
  }

  async sign(data: SigningAnAgreement): Promise<any> {
    return new Promise((resolve, reject) => {
      return EgalActionConstructor.custom(
        Microservices.Core,
        APIModels.Partner,
        'sign',
        data,
      )
        .call()
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async downloadReportPartners(
    filter: IFilter,
    sort: ISortPartners,
  ): Promise<any> {
    const headers = {
      headers: {
        Authorization: cookies.get('core'),
      },
    }
    return new Promise((resolve, reject) => {
      return axiosInstance
        .post(
          `${process.env.VUE_APP_BASE_URL}/core/Partner/downloadReport`,
          {
            filter: filter || [],
            order: sort || [],
          },
          headers,
        )
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  async generateContract(id: number): Promise<any> {
    const headers = {
      headers: {
        Authorization: cookies.get('core'),
      },
    }
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(
          `${process.env.VUE_APP_BASE_URL}/core/Partner/generateContract`,
          {
            id,
          },
          headers,
        )
        .then((response) => resolve(response.data.action_result?.data))
        .catch((error) => reject(error))
    })
  }

  async myPartners(filter?: IFilter, sort?: ISortPartners): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = {
        headers: {
          Authorization: cookies.get('core'),
        },
      }
      axiosInstance
        .post(
          `${process.env.VUE_APP_BASE_URL}/core/Partner/myPartners`,
          {
            pagination: {
              per_page: 100,
              page: 1,
            },
            filter: filter || [],
            withs: [
              'farmer',
              'farmer.company_rating',
              'customer',
              'customer.company_rating',
            ],
            order: sort || [],
          },
          headers,
        )
        .then((response) => resolve(response.data.action_result.data.items))
        .catch((error) => reject(error))
    })
  }
}

const PartnersAPI = new PartnersAPIModel()
export { PartnersAPI }
