import { defineStore } from 'pinia'
import { ModalKeys } from './ModalKeys'

export const useModalStore = defineStore('modal', {
  state: () => ({
    modals: {
      [ModalKeys.FeedbackForm]: {
        key: ModalKeys.FeedbackForm,
        options: {
          isClosable: true,
          class: 'feedback-modal',
        },
        isOpen: false,
      },
      [ModalKeys.VerificationFull]: {
        key: ModalKeys.VerificationFull,
        options: {
          isClosable: false,
          class: 'verification-modal',
        },
        isOpen: false,
      },
    },
  }),
  actions: {
    openModal(key: ModalKeys, options: any) {
      console.log('Открываем модалку:', key)
      this.modals[key] = { ...this.modals[key], isOpen: true, options }
    },
    closeModal(key: ModalKeys) {
      this.modals[key] = {
        ...this.modals[key],
        isOpen: false,
        options: {} as any,
      }
    },
    isModalOpen(key: ModalKeys) {
      console.log('this.modals[key]?.isOpen', this.modals[key]?.isOpen)
      return this.modals[key]?.isOpen
    },
    getModal(key: ModalKeys) {
      return this.modals[key]
    },
    getModals() {
      return this.modals
    },
  },
})
